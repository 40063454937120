@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
:root{
  --background-color: #222222;
  --primary-color: #FFFFFF;
  --secondary-color: #44d3ff;
  --tertiary-color: #95A3B3;
  --quaternary-color: #424242;
  --font1: 'VT323', sans-serif, monospace;
}

* {
margin: 0;
padding: 0;
box-sizing: border-box;
font-family: var(--font1);
}

.login-div {
  background-color: var(--background-color);
}

.login-logo {
  width: 125px;
  height: 125px;
}


header {
  background-color: var(--background-color);
  color: var(--secondary-color);
}

main {
  color: var(--primary-color);
  background-color: var(--background-color);
}

h1 {
  padding-top: 45px;
}
/* CARDS */
.card {
  margin: 0 0 20px 0;
  border: 10px solid white;
  background-color: var(--tertiary-color);
  color: var(--quaternary-color);
}

.card-header {
  color: var(--primary-color);
  margin: 0;
  background-color: var(--secondary-color);
  padding: 0.625rem;
  border-radius: calc(0.18rem - 1px) calc(0.18rem - 1px) 0 0;
}

.card-body {
  padding: 2.5%;
  font-size: 1.4rem;
  background-color: var(--secondary-color);
}

/* Developer Page Button CSS */
.card a {
  text-decoration: none;
}
.button-59 {
  align-items: center;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 4px;
  box-sizing: border-box;
  color: var(--background-color);
  cursor: pointer;
  display: inline-flex;
  fill: #000;
  font-size: 25px;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  min-width: 20px;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-59:focus {
  color: #171e29;
}

.button-59:hover {
  border-color: indigo;
  color: indigo;
  fill: indigo;
}

.connection-buttons a{
  text-decoration: none;
  color: indigo;
}

.btn-connection {
  border: 0;
  cursor: pointer;
  background: var(--secondary-color);
  border-radius: 8px;
  padding: 14px 20px;
  font-size: 18px;
  line-height: 1;
}

.btn-connection:hover{
  transform: translateY(-2px);
}




/* FORM */
.form-label,
.form-input,
.form-textarea {
  display: block;
}

.form-label {
  font-size: 1.2rem;
  margin: 0.625rem 0 0.25rem;
}

.form-input {
  width: 100%;
  padding: 0.75rem;
  margin: 0.2rem 0;
  font-size: 1.2rem;
  border: 2px solid var(--dark);
  border-radius: var(--border-radius);
}

.nav{
  width: 75vh;
}

.navbar-brand {
  position: relative;
  background: url(../public/devnetlogo.jpg);
  width: 75px;
  left: 15px;
  background-size: contain;
}